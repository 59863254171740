import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import BoxPricing from 'components/BoxPricing'
import { Link } from 'react-router-dom'
import { getSessionStorageItem } from 'utils/storage-handler'
import style from './style.module.css'

export default () => {
  const { name } = getSessionStorageItem('user') || {}

  return (
    <div className={style.container}>
      <Container>
        <h4 className={style.title}>Olá{name && `, ${name}`}!</h4>
        <p className={style.text}>
          Faltam poucos passos para você potencializar o uso de suas redes sociais!
        </p>
      </Container>

      <BoxPricing />

      <Container>
        <p className={style.text}>Como deseja realizar sua assinatura?</p>

        <div className={style.nav}>
          <Link className={style.navLink} to="/cartao-de-credito">
            <Button color="secondary" className={style.navButton}>
              Cartão de crédito
            </Button>
          </Link>

          <p className={style.navTextHelp}>ou</p>

          <Link className={style.navLink} to="/boleto">
            <Button color="secondary" className={style.navButton}>
              Boleto*
            </Button>
          </Link>
        </div>

        <p className={style.textPayment}>
          *Assinaturas por boleto são liberadas em até 3 dias úteis após o pagamento
        </p>
      </Container>
    </div>
  )
}
