/* eslint no-underscore-dangle: 0 */
import { getSessionStorageItem } from 'utils/storage-handler'

const init = (callback) => {
  const user = getSessionStorageItem('user')
  window.analytics = window.analytics || []
  const analytics = window.analytics || []

  if (!analytics.initialize && analytics.invoked) {
    // eslint-disable-next-line no-console
    console.error('Segment snippet included twice.')
    return
  }

  analytics.invoked = !0
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
  ]

  analytics.factory = (t) =>
    function factory(...rest) {
      const e = Array.prototype.slice.call(rest)
      e.unshift(t)
      analytics.push(e)
      return analytics
    }

  for (let t = 0; t < analytics.methods.length; t += 1) {
    const e = analytics.methods[t]
    analytics[e] = analytics.factory(e)
  }

  analytics.load = (token, e) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = !0
    script.src = `https://cdn.segment.com/analytics.js/v1/${token}/analytics.min.js`

    const a = document.getElementsByTagName('script')[0]
    a.parentNode.insertBefore(script, a)

    analytics._loadOptions = e

    if (user) {
      analytics.identify(user.id, user)
    }
  }

  analytics.SNIPPET_VERSION = '4.1.0'
  analytics.load(process.env.REACT_APP_SEGMENT_TOKEN)

  analytics.ready(() => {
    if (process.env.NODE_ENV !== 'production') {
      analytics.debug()
    }

    if (callback) {
      callback()
    }
  })
}

const setUser = (user) => {
  const { analytics } = window
  analytics.identify(user.id, user)
}

const track = (event, metadata) => {
  const { analytics } = window
  if (analytics) {
    analytics.track(event, metadata)
  }
}

const page = () => {
  const { analytics } = window
  if (analytics) {
    analytics.page()
  }
}

export default {
  init,
  setUser,
  track,
  page,
}
