import React from 'react'
import PropTypes from 'prop-types'

const Logo = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 610 157.02">
    <g>
      <g>
        <path
          className="cls-1"
          d="M0,155.32H14.34l10.07-36.87,3.75-13.83L46.59,36,65.2,104.62,69,118.45l9.9,36.87H94.73L53.08,1.71H41.47L0,155.32Zm205,0h14.34l10.07-36.87,3.75-13.83L251.58,36l18.6,68.61,3.76,13.83,9.89,36.87h15.88L258.06,1.71h-11.6L205,155.32ZM610,1.71H594.81V105c0,10.75.17,23.55-4.78,30.89-4.44,6.48-13,7.34-21,7.34s-16.39-.86-20.82-7.34c-5-7.34-5-20.14-5-30.89V1.71H528.08V105c0,14.68,0,32.25,7.85,42.15,7.51,9.56,20.82,9.9,33.11,9.9s25.77-.34,33.28-9.9c7.85-9.9,7.68-27.47,7.68-42.15V1.71ZM454.17,13.82c12.29,0,25.43,2,31.58,12.29,7.34,12.29,7.51,34.31,7.51,52.4s-.17,40.11-7.51,52.4c-6.15,10.24-19.29,12.29-31.58,12.29s-25.43-2-31.57-12.29c-7.34-12.29-7.34-34.31-7.34-52.4s0-40.11,7.34-52.4c6.14-10.24,19.28-12.29,31.57-12.29Zm0-13.82c-16.55,0-34.65,1.36-43.86,14.85-10.24,14.85-10.24,41.64-10.24,63.66s0,48.82,10.24,63.66c9.21,13.49,27.31,14.85,43.86,14.85s34.82-1.36,44-14.85c10.24-14.84,10.24-41.64,10.24-63.66s0-48.81-10.24-63.66C489,1.36,470.73,0,454.17,0Zm-148,15.53h63.67L306.19,143.71v11.61h81.25V141.49h-63.5l63.5-128.18V1.71H306.19V15.53ZM109.06,155.32h15.19V87h51.21v68.28h15.19V1.71H175.46V73.05H124.25V1.71H109.06Z"
        />
      </g>
    </g>
  </svg>
)

Logo.propTypes = {
  className: PropTypes.string,
}

Logo.defaultProps = {
  className: '',
}

export default Logo
