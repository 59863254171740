import React, { Component } from 'react'
import cogoToast from 'cogo-toast'
import msk from 'msk'
import { isCpf, isCnpj } from 'validator-brazil'
import cep from 'cep-promise'
import { getSessionStorageItem, setSessionStorageItem } from 'utils/storage-handler'
import { planPricing } from 'utils/plan-pricing'
import { postCustomer, postPrimeInvoice, putChangePlan } from 'utils/api'
import tracking from 'utils/tracking'
import { Link, Redirect } from 'react-router-dom'
import { FaRegCreditCard } from 'react-icons/fa'
import { MdDone } from 'react-icons/md'
import Loader from 'components/Loader'
import Button from 'components/Button'
import Field from 'components/Field'
import Select from 'components/Select'
import citiesAndStates from './cities-and-states.json'
import style from './style.module.css'

class FormBankSlip extends Component {
  constructor() {
    super()

    const { name, email } = getSessionStorageItem('user') || {}

    this.state = {
      isSending: false,
      isSuccess: false,
      name: name || '',
      email: email || '',
      document: '',
      addressZipCode: '',
      addressCity: '',
      addressUf: null,
      addressStreet: '',
      addressNumber: '',
      addressComplement: '',
      addressDistrict: '',
      optionsStates: citiesAndStates.map((item) => ({
        label: item.nome,
        value: item.sigla,
      })),
      optionsCities: [],
    }
  }

  handleFieldChange = (e) => {
    const { name } = e.target
    let { value } = e.target

    const masksMap = {
      addressZipCode: '99999-999',
      cpf: '999.999.999-99',
      cnpj: '99.999.999/9999-99',
    }

    // generic masks
    if (masksMap[name]) {
      value = msk.fit(value, masksMap[name])
    }

    // document mask
    if (name === 'document') {
      value = value.length > 14 ? msk.fit(value, masksMap.cnpj) : msk.fit(value, masksMap.cpf)
    }

    this.setState({ [name]: value })
  }

  handleStateChange = (e) => {
    const { value } = e.target
    this.setAddressUf(value)
  }

  setAddressUf = (value, callback) => {
    // set state and get updated cities options
    this.setState({ addressUf: this.getState(value) }, () => {
      const stateIndex = citiesAndStates.findIndex((item) => item.sigla === value)
      const optionsCities = citiesAndStates[stateIndex].cidades.map((item) => ({
        label: item,
        value: item,
      }))

      this.setState({ addressCity: null, optionsCities }, () => {
        if (callback) {
          callback()
        }
      })
    })
  }

  handleCityChange = (e) => {
    const { value } = e.target
    this.setAddressCity(value)
  }

  setAddressCity = (value, callback) => {
    this.setState({ addressCity: { value, label: value } }, () => {
      if (callback) {
        callback()
      }
    })
  }

  getState = (value) => {
    const { optionsStates } = this.state
    const stateIndex = optionsStates.findIndex((item) => item.value === value)
    return optionsStates[stateIndex]
  }

  getAddressByCep = (e) => {
    const { value } = e.target

    if (value.length === 9) {
      cep(value).then((response) => {
        const { city, neighborhood, state, street } = response

        this.setAddressUf(state, () => {
          this.setAddressCity(city, () => {
            this.setState({
              addressDistrict: neighborhood,
              addressStreet: street,
            })
          })
        })
      })
    }
  }

  showErrorMessage = (message) => {
    cogoToast.warn(message, {
      position: 'bottom-center',
    })

    this.setState({
      isSending: false,
      isSuccess: false,
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    const {
      name,
      email,
      document,
      addressZipCode,
      addressCity,
      addressUf,
      addressStreet,
      addressNumber,
      addressComplement,
      addressDistrict,
    } = this.state

    const user = getSessionStorageItem('user') || {}
    const plan = getSessionStorageItem('plan')
    const currentPricing = planPricing()
    const currentPricingCents = parseInt(currentPricing * 100, 10)

    if (!isCpf(document) && !isCnpj(document)) {
      this.showErrorMessage('O documento informado é inválido')
      return
    }

    this.setState({ isSending: true })

    tracking.track('clicked_payment', {
      method: 'bank_slip',
      plan,
    })

    const customerResponse = await postCustomer({
      email,
      name,
      cpf_cnpj: document,
      zip_code: addressZipCode,
      street: addressStreet,
      number: addressNumber,
      district: addressDistrict,
      complement: addressComplement,
      uf: addressUf,
      city: addressCity,
    })

    if (customerResponse.error) {
      this.showErrorMessage(
        customerResponse.data.customer_message || 'Por favor, revise as informações preenchidas',
      )
      return
    }

    let primeInvoiceResponse

    if (user.hasSubscription) {
      primeInvoiceResponse = await putChangePlan({
        payment_method: 'bank_slip',
        plan: plan || process.env.REACT_APP_DEFAULT_PLAN,
      })
    } else {
      primeInvoiceResponse = await postPrimeInvoice({
        method: 'bank_slip',
        plan: plan || process.env.REACT_APP_DEFAULT_PLAN,
        price_cents: currentPricingCents,
        discount_cents: 0,
      })
    }

    if (primeInvoiceResponse.error) {
      this.showErrorMessage('Por favor, revise as informações preenchidas')
      return
    }

    // Necessário porque API está sem padrão para retorno de acordo com a chamada que é feita
    const bankSlipUrl = primeInvoiceResponse.charge ?
      primeInvoiceResponse.charge.pdf :
      primeInvoiceResponse.bank_slip_url

    setSessionStorageItem('bankSlip', {
      pdf: bankSlipUrl,
      url: bankSlipUrl,
    })

    this.setState({
      isSending: false,
      isSuccess: true,
    })
  }

  render() {
    const {
      isSending,
      isSuccess,
      name,
      email,
      document,
      addressZipCode,
      addressCity,
      addressUf,
      addressStreet,
      addressNumber,
      addressComplement,
      addressDistrict,
      optionsStates,
      optionsCities,
    } = this.state

    if (isSuccess) {
      return <Redirect to="/sucesso-boleto" />
    }

    return (
      <div className={style.container}>
        <div className={style.formContainer}>
          <form className={style.form} onSubmit={this.handleSubmit}>
            <Field
              required
              className={style.field}
              name="name"
              value={name}
              onChange={this.handleFieldChange}
              placeholder="Digite seu nome"
              label="Nome completo"
            />

            <Field
              required
              className={style.field}
              name="email"
              value={email}
              type="email"
              onChange={this.handleFieldChange}
              placeholder="Digite seu email"
              label="E-mail"
            />

            <Field
              required
              className={style.field}
              name="document"
              value={document}
              onChange={this.handleFieldChange}
              placeholder="012.345.678-90"
              label="CPF ou CNPJ"
              inputmode="numeric"
            />

            <div className={style.fieldRow}>
              <Field
                required
                className={style.field}
                name="addressZipCode"
                value={addressZipCode}
                onChange={this.handleFieldChange}
                onBlur={this.getAddressByCep}
                placeholder="86099-101"
                label="CEP"
                inputmode="numeric"
              />

              <Select
                required
                className={style.field}
                name="addressUf"
                selectedOption={addressUf}
                onChange={this.handleStateChange}
                placeholder="Selecione"
                label="Estado"
                options={optionsStates}
                isSearchable
              />

              <Select
                required
                className={style.field}
                name="addressCity"
                selectedOption={addressCity}
                onChange={this.handleCityChange}
                placeholder="Selecione"
                label="Cidade"
                options={optionsCities}
                isSearchable
              />

              <Field
                required
                className={style.field}
                name="addressDistrict"
                value={addressDistrict}
                onChange={this.handleFieldChange}
                placeholder="Digite o bairro"
                label="Bairro"
              />
            </div>

            <div className={style.fieldRow}>
              <Field
                required
                className={style.field}
                name="addressStreet"
                value={addressStreet}
                onChange={this.handleFieldChange}
                placeholder="Digite a rua"
                label="Rua"
              />

              <Field
                required
                className={style.field}
                name="addressNumber"
                value={addressNumber}
                onChange={this.handleFieldChange}
                placeholder="Digite o número"
                label="Número"
                inputmode="numeric"
              />

              <Field
                className={style.field}
                name="addressComplement"
                value={addressComplement}
                onChange={this.handleFieldChange}
                placeholder="Digite o complemento"
                label="Complemento"
              />
            </div>

            <Button
              color="secondary"
              type="submit"
              className={style.button}
              disabled={isSending || isSuccess}
            >
              {isSuccess && (
                <>
                  <MdDone /> Pronto
                </>
              )}
              {isSending && !isSuccess && (
                <>
                  <Loader /> Processando
                </>
              )}

              {!isSending && !isSuccess && 'Gerar boleto'}
            </Button>

            <p className={style.textPayment}>
              Assinaturas por boleto são liberadas em até 3 dias úteis após o pagamento
            </p>
          </form>
          <div className={style.nav}>
            <Link to="/cartao-de-credito" className={style.navItem}>
              <FaRegCreditCard className={style.navItemIcon} />
              Assinar por Cartão de Crédito
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default FormBankSlip
