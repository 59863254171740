import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import CreditCard from 'pages/CreditCard'
import SetUser from 'pages/SetUser'
import Home from 'pages/Home'
import BankSlip from 'pages/BankSlip'
import App from 'components/App'
import SuccessBankSlip from 'pages/SuccessBankSlip'
import SuccessCreditCard from 'pages/SucessCreditCard'
import NotAllowed from 'pages/NotAllowed'
import { getSessionStorageItem } from 'utils/storage-handler'

const PrivateRoute = ({ component: Component, reverseCondition, redirectTo, ...rest }) => {
  const user = getSessionStorageItem('user') || {}
  let isAllowed =
    Boolean(user.id) && (!user.hasSubscription || user.subscriptionStatus !== 'active')

  if (reverseCondition) {
    isAllowed = !isAllowed
  }

  return (
    <Route
      {...rest}
      render={(props) => (isAllowed ? <Component {...props} /> : <Redirect to={redirectTo} />)}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  reverseCondition: PropTypes.bool,
  redirectTo: PropTypes.string,
}

PrivateRoute.defaultProps = {
  reverseCondition: false,
  redirectTo: `/not-allowed`,
}

export default function Routes() {
  return (
    <Router>
      <App>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />

          <PrivateRoute exact path="/cartao-de-credito" component={CreditCard} />

          <PrivateRoute exact path="/boleto" component={BankSlip} />

          <PrivateRoute exact path="/sucesso-cartao" component={SuccessCreditCard} />

          <PrivateRoute exact path="/sucesso-boleto" component={SuccessBankSlip} />

          <PrivateRoute
            reverseCondition
            redirectTo="/"
            exact
            path="/not-allowed"
            component={NotAllowed}
          />

          <Route exact path="/user/:userToken" component={SetUser} />
          <Route exact path="/user/:userToken/:plan/" component={SetUser} />

          <PrivateRoute component={Home} />
        </Switch>
      </App>
    </Router>
  )
}
