import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import FormBankSlip from 'components/FormBankSlip'
import Container from 'components/Container'
import BoxPricing from 'components/BoxPricing'
import tracking from 'utils/tracking'

export default () => {
  useEffect(() => {
    tracking.track('method_plan', { method_plan: 'bank_slip' })
    tracking.page()
  })

  return (
    <>
      <Helmet>
        <title>Ahazou - Assine Agora: Pagar com boleto</title>
        <meta name="description" content="Formulário de assinatura da Ahazou: Pagar com boleto" />
      </Helmet>

      <Container>
        <BoxPricing />
        <FormBankSlip />
      </Container>
    </>
  )
}
