import React, { Component } from 'react'
import cogoToast from 'cogo-toast'
import msk from 'msk'
import { isCpf, isCnpj } from 'validator-brazil'
import { postCustomer, postPaymentMethod, postPrimeInvoice, putChangePlan } from 'utils/api'
import { getSessionStorageItem } from 'utils/storage-handler'
import Cards from 'react-credit-cards'
import { Link, Redirect } from 'react-router-dom'
import { FaMoneyCheckAlt } from 'react-icons/fa'
import { MdDone } from 'react-icons/md'
import creditCardsImages from 'utils/credit-cards-images'
import { planPricing } from 'utils/plan-pricing'
import tracking from 'utils/tracking'
import Loader from 'components/Loader'
import Button from 'components/Button'
import Field from 'components/Field'
import style from './style.module.css'
import 'react-credit-cards/es/styles-compiled.css'

const { Iugu } = window

if (process.env.NODE_ENV !== 'production') {
  Iugu.setTestMode(true)
}

Iugu.setAccountID(process.env.REACT_APP_IUGU_ACCOUNT_ID)

class FormCreditCard extends Component {
  constructor() {
    super()

    this.state = {
      isSending: false,
      isSuccess: false,
      name: '',
      number: '',
      expiry: '',
      cvc: '',
      document: '',
      focused: '',
      brand: '',
    }
  }

  getBrandByNumber = (number) => {
    const brand = Iugu.utils.getBrandByCreditCardNumber(number) || 'placeholder'
    this.setState({ brand })
  }

  handleFieldChange = (e) => {
    const { name } = e.target
    let { value } = e.target

    const masksMap = {
      number: '9999 9999 9999 9999',
      expiry: '99 / 99',
      cvc: '9999',
      cpf: '999.999.999-99',
      cnpj: '99.999.999/9999-99',
    }

    // generic masks
    if (masksMap[name]) {
      value = msk.fit(value, masksMap[name])
    }

    // document mask
    if (name === 'document') {
      value = value.length > 14 ? msk.fit(value, masksMap.cnpj) : msk.fit(value, masksMap.cpf)
    }

    this.setState({ [name]: value })

    if (name === 'number') {
      this.getBrandByNumber(value)
    }
  }

  handleFieldFocused = (e) => {
    const { name } = e.target
    this.setState({ focused: name })
  }

  showErrorMessage = (message) => {
    cogoToast.warn(message, {
      position: 'bottom-center',
    })

    this.setState({
      isSending: false,
      isSuccess: false,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { name, number, expiry, cvc, document } = this.state
    const user = getSessionStorageItem('user') || {}
    const plan = getSessionStorageItem('plan')
    const currentPricing = planPricing()
    const currentPricingCents = parseInt(currentPricing * 100, 10)

    const expirySplitted = expiry.replace(/\s/g, '').split('/')
    const firstName = name
      .trim()
      .replace(/\s\s/g, '')
      .split(' ')
      .slice(0, 1)
      .join(' ')

    const lastName = name
      .trim()
      .replace(/\s\s/g, '')
      .split(' ')
      .slice(1)
      .join(' ')

    const expiryMonth = expirySplitted[0]
    const expiryYear = `20${expirySplitted[1]}`

    const cc = Iugu.CreditCard(number, expiryMonth, expiryYear, firstName, lastName, cvc)

    if (!isCpf(document) && !isCnpj(document)) {
      this.showErrorMessage('O documento informado é inválido')
      return
    }

    if (!cc.valid()) {
      this.showErrorMessage('Por favor, revise as informações do cartão')
      return
    }

    if (!Iugu.utils.validateExpirationString(expiry.replace(/\s/g, ''))) {
      this.showErrorMessage('A data de validade informadá é inválida')
      return
    }

    if (!Iugu.utils.validateCVV(cvc, Iugu.utils.getBrandByCreditCardNumber(number))) {
      this.showErrorMessage('O código de segurança informado é inválido')
      return
    }

    if (!Iugu.utils.validateCreditCardNumber(number)) {
      this.showErrorMessage('O número do cartão informado é inválido')
      return
    }

    tracking.track('clicked_payment', {
      method: 'cc',
      plan,
    })

    this.setState({ isSending: true })

    try {
      Iugu.createPaymentToken(cc, async (response) => {
        if (response.errors) {
          cogoToast.warn('Por favor, revise as informações do cartão', {
            position: 'bottom-center',
          })

          this.setState({
            isSending: false,
            isSuccess: false,
          })
          return
        }

        const token = response.id

        const customerResponse = await postCustomer({
          email: user.email,
          name,
          cpf_cnpj: document,
        })

        if (customerResponse.error) {
          this.showErrorMessage(
            customerResponse.data.customer_message ||
              'Por favor, revise as informações preenchidas',
          )
          return
        }

        const paymentMethod = await postPaymentMethod({
          token,
          description: 'Cartão de Crédito',
        })

        if (paymentMethod.error) {
          this.showErrorMessage('Por favor, revise as informações do cartão')
          return
        }

        let primeInvoice

        if (user.hasSubscription) {
          primeInvoice = await putChangePlan({
            payment_method: 'credit_card',
            plan: plan || process.env.REACT_APP_DEFAULT_PLAN,
          })
        } else {
          primeInvoice = await postPrimeInvoice({
            method: 'credit_card',
            plan: plan || process.env.REACT_APP_DEFAULT_PLAN,
            price_cents: currentPricingCents,
            discount_cents: 0,
          })
        }

        if (primeInvoice.error) {
          this.showErrorMessage('Por favor, revise as informações do cartão')
          return
        }

        this.setState({
          isSending: false,
          isSuccess: true,
        })
      })
    } catch (error) {
      cogoToast.warn('Por favor, revise as informações preenchidas', {
        position: 'bottom-center',
      })

      this.setState({
        isSending: false,
        isSuccess: false,
      })
    }
  }

  render() {
    const { isSending, isSuccess, name, number, expiry, cvc, document, brand, focused } = this.state

    if (isSuccess) {
      return <Redirect to="/sucesso-cartao" />
    }

    return (
      <div className={style.container}>
        <div className={style.preview}>
          <Cards
            locale={{
              valid: 'Validade',
            }}
            placeholders={{
              name: 'SEU NOME AQUI',
            }}
            name={name}
            number={number}
            expiry={expiry.replace('/', '')}
            cvc={cvc}
            focused={focused}
          />
        </div>

        <div className={style.formContainer}>
          <form className={style.form} onSubmit={this.handleSubmit}>
            <Field
              required
              className={style.field}
              name="name"
              value={name}
              onChange={this.handleFieldChange}
              onFocus={this.handleFieldFocused}
              placeholder="Seu nome aqui"
              label="Nome do titular"
              autoComplete="cc-name"
            />

            <div className={style.fieldNumber}>
              <Field
                required
                className={style.field}
                name="number"
                value={number}
                onChange={this.handleFieldChange}
                onFocus={this.handleFieldFocused}
                placeholder="0123 4567 8910 1112"
                label="Número do cartão"
                autoComplete="cc-number"
                inputmode="numeric"
              />
              <span
                className={style.fieldNumberIcon}
                style={{ backgroundImage: `url(${creditCardsImages[brand]})` }}
              />
            </div>

            <div className={style.fieldRow}>
              <Field
                required
                className={style.field}
                name="expiry"
                value={expiry}
                onChange={this.handleFieldChange}
                onFocus={this.handleFieldFocused}
                placeholder="MM/AA"
                label="Validade"
                autoComplete="cc-exp"
                inputmode="numeric"
              />

              <Field
                required
                className={style.field}
                name="cvc"
                value={cvc}
                onChange={this.handleFieldChange}
                onFocus={this.handleFieldFocused}
                placeholder="CVV"
                label="Código de segurança"
                autoComplete="cc-csc"
                inputmode="numeric"
              />
            </div>

            <Field
              required
              className={style.field}
              name="document"
              value={document}
              onChange={this.handleFieldChange}
              onFocus={this.handleFieldFocused}
              placeholder="012.345.678-90"
              label="CPF ou CNPJ"
              inputmode="numeric"
            />

            <Button
              color="secondary"
              type="submit"
              className={style.button}
              disabled={isSending || isSuccess}
            >
              {isSuccess && (
                <>
                  <MdDone /> Pronto
                </>
              )}
              {isSending && !isSuccess && (
                <>
                  <Loader /> Processando
                </>
              )}
              {!isSending && !isSuccess && 'Confirmar assinatura'}
            </Button>
          </form>
          <div className={style.nav}>
            <Link to="/boleto" className={style.navItem}>
              <FaMoneyCheckAlt className={style.navItemIcon} />
              Assinar por boleto
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default FormCreditCard
