import React from 'react'
import classNames from 'classnames'
import { planPricing, isCarnavalPlan } from 'utils/plan-pricing'
import formatCurrency from 'utils/format-currency'
import { isBrowser } from 'react-device-detect'
import imgCarnaval1 from 'images/carnaval-1.webp'
import style from './style.module.css'

export default () => {
  const currentPricing = planPricing()
  const originalPricing = 49
  const discount = Math.floor(100 - (currentPricing / originalPricing) * 100)

  if (discount && isCarnavalPlan()) {
    return (
      <>
        <div className={style.specialPlanImage2} />

        <div className={classNames(style.pricing, style.specialPlan)}>
          {isBrowser ? (
            <img
              className={style.specialPlanImage1}
              alt="Confetes de carnaval"
              src={imgCarnaval1}
            />
          ) : null}

          <p className={style.text}>
            <span className={style.specialPlanText}>
              Promoção de <b>CARNAVAL</b>
            </span>
            <br />
            Acesso <b>ilimitado</b> aos posts do AHAZOU de{' '}
            <b>
              <strike>R$${formatCurrency(originalPricing)}</strike>
            </b>{' '}
            por
          </p>

          <p className={classNames(style.text, style.pricingWithDiscount)}>
            <b>R${formatCurrency(currentPricing)}</b>/mês
          </p>

          <p className={classNames(style.text, style.hint)}>
            * pelo primeiro mês. Após isso será cobrado R$29,00 por mês
          </p>
        </div>
      </>
    )
  }

  return (
    <div className={style.pricing}>
      {discount ? (
        <p className={style.text}>
          Acesso <b>ilimitado</b> aos posts do AHAZOU de{' '}
          <b>
            <strike>R$${formatCurrency(originalPricing)}</strike>
          </b>{' '}
          por
        </p>
      ) : (
        <p className={style.text}>
          Acesso <b>ilimitado</b> aos posts do AHAZOU por
        </p>
      )}

      <p className={style.pricingWithDiscount}>
        <b>R${formatCurrency(currentPricing)}</b>/mês
      </p>

      {discount ? (
        <span className={style.discount}>
          {discount}%<br />
          OFF
        </span>
      ) : null}
    </div>
  )
}
