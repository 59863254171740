import React from 'react'
import PropTypes from 'prop-types'
import Header from 'components/Header'
import style from './style.module.css'

const Container = ({ children }) => (
  <div>
    <Header />

    <main className={style.content}>{children}</main>
  </div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
