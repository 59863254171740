import React from 'react'
import { AndroidView, IOSView, BrowserView } from 'react-device-detect'
import BoxInfo from 'components/BoxInfo'
import imgBadgeGoogle from 'images/badge-google.png'
import imgBadgeApple from 'images/badge-apple.svg'
import style from './style.module.css'

export default () => (
  <div className={style.container}>
    <BoxInfo text="Ei, parece que você já possui uma assinatura no Ahazou!" emoji="thinking" />

    <BrowserView>
      <div className={style.text}>
        <p>
          <b>Mas espere!</b> <br />
          Caso você precise gerenciar sua assinatura, basta instalar um de nossos aplicativos e ir
          em &quot;Assinatura Prime&quot;
        </p>
      </div>
    </BrowserView>

    <AndroidView>
      <div className={style.text}>
        <p>
          <b>Mas espere!</b> <br />
          Caso você precise gerenciar sua assinatura, basta clicar no botão abaixo ou seguir os
          passos no seu dispositivo Android
        </p>

        <a
          className={style.link}
          href="https://ahazou.com/?screen=ahzproapp.carreirabeauty.com.ahz_pro.refactored.ui.primeadmin.view.PrimeAdminActivity"
        >
          Abrir Minhas Assinaturas
        </a>

        <p>ou</p>

        <ol>
          <li>
            <p>Abra o aplicativo Ahazou</p>
          </li>
          <li>
            <p>Clique na sua foto de perfil no canto superior esquerdo</p>
          </li>
          <li>
            <p>
              Clique em <b>&quot;Assinatura Prime&quot;</b>
            </p>
          </li>
          <li>
            <p>
              Caso sua assinatura esteja <b>DESATIVADA</b>, clique em{' '}
              <b>&quot;ATIVAR ASSINATURA&quot;</b>
            </p>
          </li>
        </ol>
      </div>
    </AndroidView>

    <IOSView>
      <div className={style.text}>
        <p>
          <b>Mas espere!</b> <br />
          Caso você precise gerenciar sua assinatura, basta seguir os passos no seu iPhone
        </p>

        <ol>
          <li>
            <p>Abra o aplicativo Ahazou</p>
          </li>
          <li>
            <p>Clique na sua foto de perfil no canto superior esquerdo</p>
          </li>
          <li>
            <p>
              Clique em <b>&quot;Assinatura Prime&quot;</b>
            </p>
          </li>
          <li>
            <p>Assine utilizando Apple Pay</p>
          </li>
        </ol>
      </div>
    </IOSView>

    <BrowserView>
      <div className={style.badges}>
        <a
          className={style.badgeLink}
          href="https://play.google.com/store/apps/details?id=ahzproapp.carreirabeauty.com.ahz_pro&hl=pt_BR&utm_source=web-checkout&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img className={style.badgeImg} alt="Disponível no Google Play" src={imgBadgeGoogle} />
        </a>

        <a className={style.badgeLink} href="https://apps.apple.com/br/app/ahazou-pro/id1145988660">
          <img className={style.badgeImg} alt="Disponível na App Store" src={imgBadgeApple} />
        </a>
      </div>
    </BrowserView>

    <AndroidView>
      <div className={style.badges}>
        <a
          className={style.badgeLink}
          href="https://play.google.com/store/apps/details?id=ahzproapp.carreirabeauty.com.ahz_pro&hl=pt_BR&utm_source=web-checkout&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img className={style.badgeImg} alt="Disponível no Google Play" src={imgBadgeGoogle} />
        </a>
      </div>
    </AndroidView>

    <IOSView>
      <div className={style.badges}>
        <a className={style.badgeLink} href="https://apps.apple.com/br/app/ahazou-pro/id1145988660">
          <img className={style.badgeImg} alt="Disponível na App Store" src={imgBadgeApple} />
        </a>
      </div>
    </IOSView>
  </div>
)
