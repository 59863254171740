import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import Container from 'components/Container'
import UserNotFound from 'components/UserNotFound'
import UserHasSubscription from 'components/UserHasSubscription'
import { getSessionStorageItem } from 'utils/storage-handler'
import tracking from 'utils/tracking'

export default () => {
  const user = getSessionStorageItem('user') || {}
  const userFound = Boolean(user.id)

  useEffect(() => {
    tracking.page()
    window.location.href = `https://app.ahazou.com`
  })

  return (
    <>
      <Helmet>
        <title>Ahazou - Assine Agora: Não conseguimos processar o usuário</title>
        <meta
          name="description"
          content="Formulário de assinatura da Ahazou: Não conseguimos processar o usuário"
        />
      </Helmet>
      <Container>{!userFound ? <UserNotFound /> : <UserHasSubscription />}</Container>
    </>
  )
}
