import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import Container from 'components/Container'
import SucessBankSlip from 'components/SucessBankSlip'
import tracking from 'utils/tracking'

export default () => {
  useEffect(() => {
    tracking.page()
  })

  return (
    <>
      <Helmet>
        <title>Ahazou - Assinatura feita com sucesso</title>
        <meta name="description" content="Assinatura feita com sucesso" />
      </Helmet>
      <Container>
        <SucessBankSlip />
      </Container>
    </>
  )
}
