import { getSessionStorageItem } from 'utils/storage-handler'

const pricingsMap = {
  prime49: 49,
  prime29: 29,
  prime19: 19,
  carnaval19: 19,
}

export function planPricing() {
  const plan = getSessionStorageItem('plan')

  return pricingsMap[plan] || pricingsMap[process.env.REACT_APP_DEFAULT_PLAN]
}

export function isCarnavalPlan() {
  const plan = getSessionStorageItem('plan')

  return plan.indexOf('carnaval19') !== -1
}
