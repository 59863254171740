import React from 'react'
import { FaHeart } from 'react-icons/fa'
import BoxInfo from 'components/BoxInfo'
import imgBadgeGoogle from 'images/badge-google.png'
import imgBadgeApple from 'images/badge-apple.svg'
import style from './style.module.css'

export default () => (
  <div className={style.container}>
    <BoxInfo text="Nós não conseguimos encontrar sua conta!" emoji="sad" />

    <div className={style.text}>
      <p>
        <b>Mas espere!</b> <br />
        Você ainda pode pode instalar um de nossos aplicativos e entrar em contato com nossa equipe
        para receber suporte
      </p>
      <FaHeart />
    </div>

    <div className={style.badges}>
      <a
        className={style.badgeLink}
        href="https://play.google.com/store/apps/details?id=ahzproapp.carreirabeauty.com.ahz_pro&hl=pt_BR&utm_source=web-checkout&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      >
        <img className={style.badgeImg} alt="Disponível no Google Play" src={imgBadgeGoogle} />
      </a>

      <a className={style.badgeLink} href="https://apps.apple.com/br/app/ahazou-pro/id1145988660">
        <img className={style.badgeImg} alt="Disponível na App Store" src={imgBadgeApple} />
      </a>
    </div>
  </div>
)
