import React from 'react'
import ReactDOM from 'react-dom'
import cwsThemeTokens from 'cws-theme-tokens'
import { hotjar } from 'react-hotjar'
import Routes from './Routes'
import * as serviceWorker from './serviceWorker'
import 'css/index.css'

hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SV)

ReactDOM.render(<Routes />, document.getElementById('root'))

const tokens = {
  colorBackground: '#999',
  colorPrimary: '#553091',
  colorSecondary: '#0e9c54',
  colorNeutral: '#B6B6B6',
}

const settings = {
  colorStep: 0.15,
  colorContrastDark: '#212121',
  colorContrastLight: '#FFF',
}

cwsThemeTokens(tokens, settings)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
