import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import style from './style.module.css'

class Field extends Component {
  constructor() {
    super()

    this.state = {
      focused: false,
    }
  }

  handleFocus = (e) => {
    const { onFocus } = this.props

    this.setState({ focused: true })

    if (onFocus) {
      onFocus(e)
    }
  }

  handleBlur = (e) => {
    const { onBlur } = this.props

    this.setState({ focused: false })

    if (onBlur) {
      onBlur(e)
    }
  }

  render() {
    const {
      className,
      value,
      name,
      label,
      onChange,
      type,
      placeholder,
      required,
      autoComplete,
      pattern,
      inputmode,
    } = this.props

    const { focused } = this.state

    return (
      <label
        className={classNames(style.field, className, {
          [style.filled]: value.length > 0,
          [style.focused]: focused,
        })}
        htmlFor={`field-${name}`}
      >
        <span className={style.label}>{label}</span>
        <input
          id={`field-${name}`}
          className={style.input}
          value={value}
          name={name}
          onChange={onChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          type={type}
          placeholder={focused ? placeholder : ''}
          required={required}
          autoComplete={autoComplete}
          pattern={pattern}
          inputMode={inputmode}
        />
      </label>
    )
  }
}

Field.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'email', 'password']),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  pattern: PropTypes.string,
  inputmode: PropTypes.string,
}

Field.defaultProps = {
  className: '',
  placeholder: '',
  type: 'text',
  onFocus: null,
  onBlur: null,
  required: false,
  autoComplete: null,
  pattern: null,
  inputmode: null,
}

export default Field
