import React from 'react'
import { Link } from 'react-router-dom'
import Logo from 'components/Logo'
import style from './style.module.css'

export default () => {
  return (
    <div className={style.wrapper}>
      <header className={style.header}>
        <Link to="/" className={style.logo}>
          <figure className={style.logo}>
            <Logo className={style.logoImg} />
          </figure>
        </Link>
      </header>
    </div>
  )
}
