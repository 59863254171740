import React from 'react'
import BoxInfo from 'components/BoxInfo'
import { getSessionStorageItem } from 'utils/storage-handler'
import gifCongratulations1 from 'images/congratulations-1.gif'
import gifCongratulations2 from 'images/congratulations-2.gif'
import gifCongratulations3 from 'images/congratulations-3.gif'
import gifCongratulations4 from 'images/congratulations-4.gif'
import gifCongratulations5 from 'images/congratulations-5.gif'
import gifCongratulations6 from 'images/congratulations-6.gif'
import gifCongratulations7 from 'images/congratulations-7.gif'
import gifCongratulations8 from 'images/congratulations-8.gif'
import style from './style.module.css'

const gifsList = [
  gifCongratulations1,
  gifCongratulations2,
  gifCongratulations3,
  gifCongratulations4,
  gifCongratulations5,
  gifCongratulations6,
  gifCongratulations7,
  gifCongratulations8,
]

const randomGifCongratulations = gifsList[Math.floor(Math.random() * gifsList.length)]

export default () => {
  const user = getSessionStorageItem('user')

  return (
    <>
      <BoxInfo emoji="eye-hearts">
        <h4 className={style.title}>
          Yeaaaah! <br />
          {user.name}, agora você faz parte da família <b>Ahazou</b>
        </h4>
      </BoxInfo>

      <div className={style.container}>
        <figure className={style.gifFigure}>
          <img className={style.gifImg} src={randomGifCongratulations} alt="Congratulations!" />
        </figure>

        <p className={style.text}>
          Seu pagamento está sendo processado. <br />
          Em breve você receberá um email de confirmação em <b>{user.email}</b>
        </p>
      </div>
    </>
  )
}
