import React from 'react'
import PropTypes from 'prop-types'
import imgEmojiThinking from 'images/emoji-thinking.svg'
import imgEmojiEyeHearts from 'images/emoji-eye-hearts.svg'
import imgEmojiSad from 'images/emoji-sad.svg'
import style from './style.module.css'

const emojisList = {
  sad: {
    img: imgEmojiSad,
    alt: 'Rosto triste',
  },
  thinking: {
    img: imgEmojiThinking,
    alt: 'Rosto pensativo',
  },
  'eye-hearts': {
    img: imgEmojiEyeHearts,
    alt: 'Rosto apaixonado',
  },
}

const BoxInfo = ({ emoji, text, children }) => (
  <div className={style.container}>
    {emoji ? (
      <figure className={style.emojiFigure}>
        <img
          src={emojisList[emoji].img}
          className={style.emojiImg}
          alt={emojisList[emoji].alt}
          width="80"
          height="80"
        />
      </figure>
    ) : null}

    {text && !children ? <h4 className={style.text}>{text}</h4> : null}

    {children}
  </div>
)

BoxInfo.propTypes = {
  emoji: PropTypes.oneOf(['sad', 'thinking', 'eye-hearts']),
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

BoxInfo.defaultProps = {
  emoji: null,
  text: null,
  children: null,
}

export default BoxInfo
