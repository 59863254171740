import stringIsJson from 'utils/string-is-json'

export const setSessionStorageItem = (key, value) => {
  let val = value

  if (typeof val !== 'string') {
    val = JSON.stringify(val)
  }

  window.sessionStorage.setItem(`${process.env.REACT_APP_SLUG}:${key}`, val)
}

export const getSessionStorageItem = (key) => {
  const value = window.sessionStorage.getItem(`${process.env.REACT_APP_SLUG}:${key}`)
  return stringIsJson(value) ? JSON.parse(value) : value
}

export const removeSessionStorageItem = (key) => {
  window.sessionStorage.removeItem(`${process.env.REACT_APP_SLUG}:${key}`)
}

export const clearSessionStorage = () => {
  window.sessionStorage.clear()
}
