import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { getUser } from 'utils/api'
import { setSessionStorageItem } from 'utils/storage-handler'
import queryString from 'query-string'
import tracking from 'utils/tracking'
import ErrorBox from 'components/Error'

export default () => {
  const { userToken, plan } = useParams()
  const [errorStatusCode, setErrorStatusCode] = useState(0)
  const location = useLocation()
  const { redirectTo } = queryString.parse(location.search)

  const getCurrentUser = useCallback(async () => {
    const userResponse = await getUser(userToken)
    const currentPlan = plan || process.env.REACT_APP_DEFAULT_PLAN

    if (userResponse.status !== 200) {
      setErrorStatusCode(userResponse.status || 1)
      return
    }

    if (userResponse && userResponse.id) {
      if (redirectTo && redirectTo.indexOf('cartao-de-credito') !== -1) {
        window.location.href = `https://app.ahazou.com/login/${userResponse.id}?redirectTo=/assinar/${currentPlan}/cc`
        return
      }

      if (redirectTo && redirectTo.indexOf('boleto') !== -1) {
        window.location.href = `https://app.ahazou.com/login/${userResponse.id}?redirectTo=/assinar/${currentPlan}/bank_slip`
        return
      }

      window.location.href = `https://app.ahazou.com/login/${userResponse.id}?redirectTo=/assinar/${currentPlan}`
      return
    }

    window.location.href = `https://app.ahazou.com`
  }, [redirectTo, userToken])

  // set currentPlan
  useEffect(() => {
    const currentPlan = plan || process.env.REACT_APP_DEFAULT_PLAN

    setSessionStorageItem('plan', currentPlan)
    tracking.track('chose_plan', { plan: currentPlan })
  }, [])

  // get and set user
  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser])

  useEffect(() => {
    if (window && window.analytics) {
      window.analytics.page()
    }
  }, [])

  if (errorStatusCode) {
    const errorProps = {
      default: {
        title: `Ocorreu um problema: ${errorStatusCode}`,
        description:
          'Estamos com algum problema técnico, por favor entre em contato com nossa equipe.',
      },
      500: {
        title: 'Erro 500',
        description: 'Estamos com algum problema técnico, por favor tente conectar mais tarde.',
      },
    }

    const currentErrorProps = errorProps[errorStatusCode] ?
      errorProps[errorStatusCode] :
      errorProps.default

    return <ErrorBox {...currentErrorProps} />
  }

  return null
}
