import React, { Component } from 'react'
import classNames from 'classnames'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'
import style from './style.module.css'
import './style.css'

class Select extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedOption: props.selectedOption,
    }

    this.onChange = this.onChange.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.selectedOption &&
      (!prevState.selectedOption ||
        nextProps.selectedOption.value !== prevState.selectedOption.value ||
        nextProps.selectedOption.label !== prevState.selectedOption.label)
    ) {
      return { selectedOption: nextProps.selectedOption }
    }

    return null
  }

  onChange(selectedOption) {
    const { name, onChange } = this.props
    this.setState({ selectedOption })

    onChange({
      target: {
        name,
        value: selectedOption.value,
      },
    })
  }

  render() {
    const {
      className,
      error,
      success,
      errorMessage,
      label,
      options,
      blurInputOnSelect,
      isSearchable,
      isDisabled,
      placeholder,
      selectId,
    } = this.props
    const { selectedOption } = this.state

    const selectClasses = classNames(style.container, className, {
      [style.success]: success,
      [style.error]: error,
    })

    return (
      <div className={selectClasses}>
        {label && (
          <label className={style.label} htmlFor={selectId}>
            {label}
          </label>
        )}
        <ReactSelect
          id={selectId}
          className={style.select}
          classNamePrefix="select"
          onChange={this.onChange}
          defaultValue={selectedOption}
          value={selectedOption}
          options={options}
          blurInputOnSelect={blurInputOnSelect}
          isSearchable={isSearchable}
          placeholder={placeholder}
          isDisabled={isDisabled}
          noOptionsMessage={() => 'Lista vazia'}
        />
        {error && <p className={style.errorMessage}>{errorMessage}</p>}
      </div>
    )
  }
}

Select.propTypes = {
  selectId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  blurInputOnSelect: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()]),
  placeholder: PropTypes.string,
}

Select.defaultProps = {
  selectId: `field-${Math.random()
    .toString(36)
    .substring(7)}`,
  blurInputOnSelect: true,
  isSearchable: false,
  isDisabled: false,
  error: false,
  success: false,
  selectedOption: null,
  errorMessage: '',
  className: '',
  placeholder: 'Selecione',
  label: '',
}

export default Select
