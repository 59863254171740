import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import FormCreditCard from 'components/FormCreditCard'
import Container from 'components/Container'
import BoxPricing from 'components/BoxPricing'
import tracking from 'utils/tracking'

export default () => {
  useEffect(() => {
    tracking.track('method_plan', { method_plan: 'cc' })
    tracking.page()
  })

  return (
    <>
      <Helmet>
        <title>Ahazou - Assine Agora: Pagar com cartão de crédito</title>
        <meta
          name="description"
          content="Formulário de assinatura da Ahazou: Pagar com cartão de crédito"
        />
      </Helmet>
      <Container>
        <BoxPricing />
        <FormCreditCard />
      </Container>
    </>
  )
}
