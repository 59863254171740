import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import Container from 'components/Container'
import Home from 'components/Home'
import tracking from 'utils/tracking'

export default () => {
  useEffect(() => {
    tracking.page()
    window.location.href = `https://app.ahazou.com`
  }, [])

  return (
    <>
      <Helmet>
        <title>Ahazou - Assine Agora</title>
        <meta name="description" content="Formulários de assinatura da Ahazou" />
      </Helmet>
      <Container>
        <Home />
      </Container>
    </>
  )
}
