import { getSessionStorageItem } from 'utils/storage-handler'
import serialize from 'utils/serialize'
import axios from 'axios'

// API settings
const API_URL = getSessionStorageItem('apiUrl') || process.env.REACT_APP_API_URL

const request = (path, method, data) => {
  const url = /^http/.test(path) ? path : `${API_URL}${path}`

  return new Promise((resolve) => {
    axios({
      url,
      method,
      data,
    })
      .then((response) => {
        const responseFormated =
          typeof response.data === 'object' ?
            {
                status: response.status,
                ...response.data,
              } :
            {
                status: response.status,
                data: response.data,
              }

        resolve(responseFormated)
      })
      .catch((error) => {
        resolve(error.response)
      })
  })
}

// http methods
const put = (path, body) => request(path, 'PUT', body)
const post = (path, body) => request(path, 'POST', body)
const get = (path, params) => request(path + (params ? `?${serialize(params)}` : ''), 'GET')

// calls
export function getUser(userToken) {
  return get(`/users/user_from_token?token=${userToken}`)
}

export function getSubscription(userId) {
  return get(`/users/${userId}/check_subscription`)
}

export function syncUserSubscription(userId) {
  return get(`https://subscription-dot-ahz-core.appspot.com/subscription/sync-firebase/${userId}`)
}

export function postCustomer(data) {
  const { id } = getSessionStorageItem('user') || {}
  return post(`/users/${id}/customer`, data)
}

export function postPaymentMethod(data) {
  const { id } = getSessionStorageItem('user') || {}
  return post(`/users/${id}/customer/payment_method`, data)
}

export function postPrimeInvoice(data) {
  const { id } = getSessionStorageItem('user') || {}
  return post(`/users/${id}/invoice/prime_invoice`, data)
}

export function putChangePlan(data) {
  const { id } = getSessionStorageItem('user') || {}
  return put(`/users/${id}/subscription/change_plan`, data)
}
